<template>
    <div class='fui-content absolute'>
        <div class="layer">
            <div class="text">
                <p>① 请点击右上角</p>
                <p>② 通过【浏览器打开】</p>
                <p>③ 完成支付</p>
                <p>④ 返回此页面</p>
            </div>
        </div>
    </div>
</template>
<script>
    import {isWeixin} from "@utils";

    export default {
        name: 'payMask',
        data() {
            return {
                theRequest: {}
            }
        },
        methods: {
// 获取当前微信浏览器url地址参数
            getUrlParams() {
// 清除旧的缓存数据
// window.localStorage.clear()
                let theRequest = new Object();
                let url = location.href; //获取url中"?"符后的字串
                let strs = [];
                if (url.indexOf("?") != -1) {
                    var str = url.substr(parseInt(url.indexOf("?") + 1));
                    strs = str.split("&");
                    for (var i = 0; i < strs.length; i++) {
                        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                    }
                }
                this.theRequest = theRequest;
            },
        },
        created() {
            this.getUrlParams();
            if (JSON.stringify(this.theRequest) != '{}') {
                if (!isWeixin()) {
                    location.href = this.theRequest.url;
                }
            }
        }
    }
</script>
<style scoped>
    .fui-content {
        background-color: rgba(0,0,0,0.4);
        background-image: url("../assets/images/alipay.png");
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center 6.5rem;
        height: -webkit-fill-available;
        position: absolute;
        z-index: 99999;
        padding-top: 0;
    }

    .layer {
        width: inherit;
        height: inherit;
        background-image: url("../assets/images/guide.png");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 28%;
        color: #fff;
    }

    .layer .text {
        color: #fff;
        font-size: 0.6rem;
        padding-top: 2rem;
        padding-left: 0.25rem;
    }

    .layer .tip {
        height: 1rem;
        left: 0;
        right: 0;
        bottom: 3rem;
        position: absolute;
        font-size: 0.8rem;
        text-align: center;
    }
</style>
